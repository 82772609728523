<template>
    <div>
        <comp-table ref="comp_table" title="平台商品管理" :columns="columns" :table-api="tableApi" :dataBefore="onDataBefore">
            <template v-slot:search="data">
                <Select v-model="data.search.supplierId" filterable style="width:200px" transfer placeholder="选择供应商">
                    <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
                </Select>
                <Select v-model="data.search.promotionType" multiple filterable style="width:200px" transfer placeholder="促销类型">
                    <Option value="isHot">热销</Option>
                    <Option value="isBest">推荐</Option>
                </Select>
            </template>
            <!-- 新增按钮 -->
            <template #operate>
                <Button type="info" @click="$refs.data_form.open()">新增</Button>
            </template>
        </comp-table>

        <DataForm :supplierList="suppliers" ref="data_form"></DataForm>
    </div>
</template>

<script>
import DataForm from "./form.vue"
import CompTable from "../../jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"

export default {
    components: { CompTable, DataForm },

    data() {
        return {
            // 供应商列表
            suppliers: null,
            // 表格接口
            tableApi: `/gateway/apps/o2o/api/o2o/goods/listAllGoodsByPage`,

            columns: [
                {
                    title: "商品ID",
                    key: "goodsId",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "商品",
                    key: "goodsName",
                    minWidth: 250,
                    align: "center",
                },
                {
                    title: "类型",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, { goods: "商品", service: "到店服务", visit_service: "上门服务", coupon: "消费券" }[params.row.serviceType] || "未知")
                    },
                },
                {
                    title: "库存",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, params.row.serviceType === "goods" ? params.row.goodsNumber : "-")
                    },
                },
                {
                    title: "单价",
                    key: "shopPrice",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "促销",
                    minWidth: 180,
                    align: "center",
                    render: (h, params) => {
                        const req = data => {
                            Request.post(
                                "/gateway/apps/o2o/api/o2o/goods/updateGoodsAttr",
                                {
                                    goodsId: params.row.goodsId,
                                    ...data,
                                },
                                { json: true }
                            ).then(() => {
                                this.$Message.success({
                                    content: "修改成功",
                                    background: true,
                                })
                            })
                        }

                        return h("div", [
                            h(
                                "Checkbox",
                                {
                                    props: {
                                        value: params.row.isHot === 1,
                                    },
                                    on: {
                                        "on-change": v => {
                                            req({
                                                isHot: v ? 1 : 0,
                                            })
                                        },
                                    },
                                },
                                "热销"
                            ),
                            h(
                                "Checkbox",
                                {
                                    props: {
                                        value: params.row.isBest === 1,
                                    },
                                    on: {
                                        "on-change": v => {
                                            req({
                                                isBest: v ? 1 : 0,
                                            })
                                        },
                                    },
                                },
                                "推荐"
                            ),
                        ])
                    },
                },
                {
                    title: "状态",
                    minWidth: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, params.row.isOnSale === 1 ? "已上架" : "已下架")
                    },
                },
                {
                    float: "right",
                    title: "操作",
                    width: 280,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.data_form.open(params.row.goodsId)
                                        },
                                    },
                                },
                                "编辑"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$Modal.confirm({
                                                title: "提示",
                                                content: "当前删除操作将不可恢复，请谨慎操作，是否继续？",
                                                onOk: () => {
                                                    this.$post(`/gateway/apps/o2o/api/o2o/goods/deleteGoods?goodsId=${params.row.goodsId}&custGlobalId=${parent.vue.loginInfo.userinfo.custGlobalId}&oemCode=${parent.vue.oemInfo.oemCode}`).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                content: "删除成功",
                                                                background: true,
                                                            })
                                                            this.$refs.comp_table.refresh()
                                                        } else {
                                                            this.$Message.error({
                                                                content: res.desc || "删除失败",
                                                                background: true,
                                                            })
                                                        }
                                                    })
                                                },
                                            })
                                        },
                                    },
                                },
                                "删除"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: params.row.isOnSale === 1 ? "warning" : "success",
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            const s = params.row.isOnSale

                                            this.$post(`/gateway/apps/o2o/api/o2o/goods/${s === 1 ? "modifyGoodsUnSale" : "modifyGoodsOnSale"}`, {
                                                goodsId: params.row.goodsId,
                                                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                                oemCode: parent.vue.oemInfo.oemCode,
                                            }).then(res => {
                                                if (res.code == 200) {
                                                    this.$Message.success(`${s === 1 ? "下架" : "上架"}成功`)
                                                    params.row.isOnSale = s === 1 ? 0 : 1
                                                }
                                            })
                                        },
                                    },
                                },
                                params.row.isOnSale === 1 ? "下架" : "上架"
                            ),

                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                    },
                                    style: "margin:3px",
                                    nativeOn: {
                                        click: () => {
                                            Request.get("/gateway/apps/o2o/api/o2o/goods/getGoodsDetail", {
                                                goodsId: params.row.goodsId,
                                            }).then(res => {
                                                // 置空 ID
                                                res.goodsId = null
                                                // 打开表单
                                                this.$refs.data_form.open(null, res)
                                            })
                                        },
                                    },
                                },
                                "复制"
                            ),
                        ])
                    },
                },
            ],
        }
    },

    mounted() {
        this.getSupplierList()
    },

    methods: {
        /**
         * 获取表格操作菜单
         */
        getTableOperate(h, params) {
            return [
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                const s = params.row.isOnSale

                                this.$post(`/gateway/apps/o2o/api/o2o/goods/${s === 1 ? "modifyGoodsUnSale" : "modifyGoodsOnSale"}`, {
                                    goodsId: params.row.goodsId,
                                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                                    oemCode: parent.vue.oemInfo.oemCode,
                                }).then(res => {
                                    if (res.code == 200) {
                                        this.$Message.success(`${s === 1 ? "下架" : "上架"}成功`)
                                        params.row.isOnSale = s === 1 ? 0 : 1
                                    }
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    params.row.isOnSale === 1 ? "下架" : "上架"
                ),
            ]
        },

        onDataBefore(data) {
            if (data.promotionType?.length > 0) {
                data.promotionType.includes("isHot") && (data.isHot = 1)
                data.promotionType.includes("isBest") && (data.isBest = 1)
                delete data.promotionType
            }
        },

        getDeleteApi(v) {
            return `/gateway/apps/o2o/api/o2o/goods/deleteGoods?goodsId=${v.goodsId}&custGlobalId=${parent.vue.loginInfo.userinfo.custGlobalId}&oemCode=${parent.vue.oemInfo.oemCode}`
        },

        getSupplierList() {
            this.$get("/gateway/apps/o2o/api/o2o/admin/listSupplierAll?supplierName=").then(res => {
                if (res.code == 200) {
                    const data = res.dataList
                    // 供应商列表
                    this.suppliers = data
                }
            })
        },
    },
}
</script>
<style lang="less"></style>
