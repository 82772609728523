<template>
    <div class="elem-menu-item-box" v-show="display">
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            display: false,
        }
    },

    props: {
        title: String,
        condition: {
            type: Function,
            required: false,
        },
    },
}
</script>

<style></style>
